import { ENDPOINTS, get } from '@/api/mainApi';
import { DocumentStatisticsSummary } from '@/interfaces/statistics/documentStatisticsSummary';
export class StatisticsRepository {

    public async getUserCountForBusiness(businessId: number, filterActiveUsers: boolean): Promise<Number> {
        let searchParams = new URLSearchParams()
        searchParams.append("active",  filterActiveUsers.toString());
        const url = `${ENDPOINTS.STATISTICS}/${ENDPOINTS.BUSINESSES}/${businessId}/${ENDPOINTS.USER_ACCOUNTS}/${ENDPOINTS.TOTAL}?` + searchParams.toString();

        return get(url)
    }

    public async getDepotCountForBusiness(businessId: number, filterActiveUsers: boolean): Promise<Number> {
        let searchParams = new URLSearchParams()
        searchParams.append("active",  filterActiveUsers.toString());
        const url = `${ENDPOINTS.STATISTICS}/${ENDPOINTS.BUSINESSES}/${businessId}/${ENDPOINTS.DEPOTS}/${ENDPOINTS.TOTAL}?` + searchParams.toString();

        return get(url)
    }

    public async getUserCountForOrganization(orgId: number, filterActiveUsers: boolean): Promise<Number> {
        let searchParams = new URLSearchParams()
        searchParams.append("active",  filterActiveUsers.toString());
        const url = `${ENDPOINTS.STATISTICS}/${ENDPOINTS.ORGANIZATIONS}/${orgId}/${ENDPOINTS.USER_ACCOUNTS}/${ENDPOINTS.TOTAL}?` + searchParams.toString();

        return get(url)
    }

    public async getDepotCountForOrganization(orgId: number, filterActiveUsers: boolean): Promise<Number> {
        let searchParams = new URLSearchParams()
        searchParams.append("active",  filterActiveUsers.toString());
        const url = `${ENDPOINTS.STATISTICS}/${ENDPOINTS.ORGANIZATIONS}/${orgId}/${ENDPOINTS.DEPOTS}/${ENDPOINTS.TOTAL}?` + searchParams.toString();

        return get(url)
    }
    
    public async getDocumentStatisticsForOrganization(orgId: number): Promise<DocumentStatisticsSummary[]> {
        const url = `${ENDPOINTS.STATISTICS}/${ENDPOINTS.ORGANIZATIONS}/${orgId}/${ENDPOINTS.DOCUMENTS}`

        return get(url);
    }

    public async getDocumentStatisticsForBusiness(businessId: number): Promise<DocumentStatisticsSummary[]> {
        const url = `${ENDPOINTS.STATISTICS}/${ENDPOINTS.BUSINESSES}/${businessId}/${ENDPOINTS.DOCUMENTS}`

        return get(url);
    }
}